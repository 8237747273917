import { graphql, navigate } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';
import get from 'lodash/get';
import './sitemap-page.scss';
import loadable from '@loadable/component';

export class SitemapPageTemplate extends React.Component<any> {
  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const SitemapCard = loadable(() => import ('../components/sitemap-page/sitemap-card/sitemap-card'));

    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    let nodes = footerData.links.concat(headerData.leftLinks);
    nodes = footerData.links.concat(headerData.rightLinks);
    nodes = nodes.filter((item,index)=>{
      return (nodes.indexOf(item) == index)
    })
    return (
      <Layout className="violet-style" footer={footerData} header={headerData}>
        <div style={{ background: '#fff' }} className="sitemap-page">
          <div className="header">
            <h1 className="title-header">Sitemap</h1>
            <p className="description">Your index to the #1 crypto resource</p>
            <div className="sitemap-page-card">
              <div className="container-lg">
                <SitemapCard title="Resources">
                  <div className="row">
                    {nodes.map((item, key) => {
                        if(item.resource){
                          return (
                            <div key={key} className="col-3 site-links">
                              <a href={item.linkUrl} className="link">
                                  {item.longName ? item.longName : item.title }
                              </a>
                            </div>
                          );
                        }
                    })}
                  </div>
                </SitemapCard>
              </div>
            </div>
            <div className="sitemap-page-content"></div>
            <div className="content-tools">
              <div className="container">
                <SitemapCard title="Tools">
                  <div className="row">
                  {nodes.map((item, key) => {
                        if(item.tool){
                          return (
                            <div key={key} className="col-3 site-links">
                              <a href={item.linkUrl} className="link">
                                  {item.longName ? item.longName : item.title }
                              </a>
                            </div>
                          );
                        }
                    })}
                  </div>
                </SitemapCard>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default SitemapPageTemplate;

export const pageQuery = graphql`
  query SitemapQuery {
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
          tool
          resource
          longName
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
          tool
          resource
          longName
        }
        rightLinks{
          title
          linkUrl
          showLink
          tool
          resource
          longName
        }
      }
    }
  }
`;